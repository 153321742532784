import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LukeImage from "../components/lukeImage";
import Carousel from "../components/carousel";
export const _frontmatter = {
  "title": "Press Start"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Hey! 👋 I'm Luke Willis.`}</h1>
    <h2>{`I'm the dApp UX guy. I build dApps that are easy and free to use.`}</h2>
    <p>{`Blockchain technology gets a bad rap. Scams and misunderstandings abound. Ultimately, blockchain is just `}<strong parentName="p">{`infrastructure that belongs to everyone`}</strong>{`. You don't have to be technical or rich to benefit from it. Blockchain isn't just a money grab. It's about giving `}<strong parentName="p">{`power to the people`}</strong>{`.`}</p>
    <hr />
    <h3>{`New here?`}</h3>
    <p>{`The easiest way to follow my work is to subscribe to my newsletter. I publish (almost) daily, keeping it short. Most days, you can level up your blockchain understanding in `}<strong parentName="p">{`less than a minute`}</strong>{`.`}</p>
    <a className="cta" href="//thekoinpress.com/#/portal/signup">Subscribe »</a>
    <hr />
    <LukeImage mdxType="LukeImage" />
    <h3>{`About Luke`}</h3>
    <p>{`I've been writing and podcasting about blockchain technology since 2021 because it helps me think deeply and learn faster. I build dApps on `}<a parentName="p" {...{
        "href": "//koinos.io"
      }}>{`the Koinos blockchain`}</a>{` because of the incredibly supportive community and accessibility to outsiders. `}</p>
    <p>{`My background is as a traditional full stack web developer -- working with various languages, frameworks, and platforms. I've consulted for companies large and small in a variety of industries, led and coached teams of developers, and built some pretty interesting software along the way.`}</p>
    <hr />
    <h3>{`My Projects`}</h3>
    <Carousel mdxType="Carousel" />
    <hr />
    <h3>{`Questions?`}</h3>
    <p>{`If you need a quick answer to get your project unstuck, you can always reach out. If your company needs additional support, I occasionally offer my services. Send me details about your problem, and we'll discuss.`}</p>
    <a className="cta" href="mailto:luke@thekoinpress.com">Email me »</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      